import Flex from 'components/Box/Flex'
import Button from 'components/Button'
import Heading from 'components/Heading'
import useElementScrollInfo from 'hooks/useElementScrollInfo'
import { useVariableLoadedListener } from 'hooks/useVariableLoadedListener'
import React, { MutableRefObject } from 'react'
import styled from 'styled-components'
import { SpaceProps } from 'styled-system'
import { Icons } from 'svgs'
import { animate } from 'utils/scroll'

interface SectionTitleProps {
  scrollRef?: MutableRefObject<HTMLDivElement>
  title: React.ReactNode | string
}

const SectionTitle: React.FC<SectionTitleProps & SpaceProps> = ({ scrollRef, title, ...props }) => {
  const { scroll, elementRef } = useElementScrollInfo('horizontal')

  const scrollElement = useVariableLoadedListener(() => {
    elementRef.current = scrollRef?.current
    return scrollRef?.current
  }, [scrollRef])

  const scrollRight = () => {
    animate('scrollLeft', scrollElement, scrollElement.scrollLeft + scrollElement.clientWidth)
  }

  const scrollLeft = () => {
    animate('scrollLeft', scrollElement, scrollElement.scrollLeft - scrollElement.clientWidth)
  }

  // TODO Please Update API for this Game Collection
  return (
    <Flex alignItems="center" justifyContent="space-between" height={['44px']} {...props}>
      <Heading scale="md">{title}</Heading>
      {scroll && scrollElement && (!scroll.end || !scroll.start) && (
        <StyledScrollButtonGroup>
          <StyledIconButton onClick={scrollLeft} disabled={scroll.start} title="scroll-right-to-left">
            <Icons.ChevronLeftIcon width="16px" height="16px" />
          </StyledIconButton>
          <StyledIconButton ml="8px" onClick={scrollRight} disabled={scroll.end} title="scroll-left-to-right">
            <Icons.ChevronRightIcon width="16px" height="16px" />
          </StyledIconButton>
        </StyledScrollButtonGroup>
      )}
    </Flex>
  )
}

const StyledIconButton = styled(Button)`
  background: ${({ theme }) => theme.colors.backgroundAlt}80 !important;
  border-radius: 50%;
  padding: 0px;
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${({ theme }) => theme.colors.textSubtle};
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.text};
    }
  }

  &:disabled {
    svg {
      fill: ${({ theme }) => theme.colors.textDisable} !important;
    }
  }
`

const StyledScrollButtonGroup = styled(Flex)`
  display: flex;
`

export default SectionTitle
