import Box from 'components/Box/Box'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { RouteConfig } from 'config/constants/route'
import { ProviderInfo } from 'config/types'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import React, { useRef } from 'react'
import { useAppSelector } from 'state'
import { useResponsiveWithoutSidebar } from 'state/app/hooks'
import { SpaceProps } from 'styled-system'
import { parselistToMatrix } from 'utils'
import { formatRoute } from 'utils/stringHelper'
import SectionTitle from './SectionTitle'
import { StyledContainerGameCollection, StyledGameProviderItem, StyledGridGame } from './styles'

const GameProvider: React.FC<SpaceProps> = ({ ...props }) => {
  const { isMobile, isTablet } = useMatchBreakpoints()

  const col = [3, 4, 5, 5, 6, 8, 10]
  const gap = [4, 4, 6, 6, 10]
  const row = isMobile || isTablet ? 1 : 2

  const providers = useAppSelector((state) => state.app.providers)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const dataToMatrix = parselistToMatrix(providers, row)

  const responsive = useResponsiveWithoutSidebar()

  return (
    <Box {...props} overflow="hidden">
      <SectionTitle title="Providers" scrollRef={providers && containerRef} mb={['10px', '', '20px']} />
      <StyledContainerGameCollection col={responsive(col)} gap={gap} row={row} itemCount={providers?.length}>
        <StyledGridGame ref={containerRef}>
          {dataToMatrix.length > 0 &&
            dataToMatrix.map((colProvider, index) => {
              return (
                <Box key={`row-grid-provider-${index}`}>
                  {colProvider.map((provider: ProviderInfo) => {
                    if (!provider) return
                    return (
                      <Box
                        key={`provider-${provider.code}`}
                        as={NextLinkFromReactRouter}
                        to={formatRoute(RouteConfig.ProviderDetail, {
                          code: provider.slug,
                        })}
                      >
                        <StyledGameProviderItem provider={provider} key={`item-provider-${provider.code}`} />
                      </Box>
                    )
                  })}
                </Box>
              )
            })}
        </StyledGridGame>
      </StyledContainerGameCollection>
    </Box>
  )
}

export default GameProvider
