import Box from 'components/Box/Box'
import { BoxProps } from 'components/Box/types'
import Image from 'components/Image'
import { NextLinkFromReactRouter } from 'components/NextLink'
import React from 'react'
import getExternalLinkProps from 'utils/getExternalLinkProps'

export interface CarouseLinkItemProps extends BoxProps {
  href?: string
  external?: boolean
  original?: boolean
  src: string
  width: number
  height: number
  alt: string
}

export const CarouseLinkItem: React.FC<CarouseLinkItemProps> = ({
  src,
  alt,
  href = '#',
  width,
  height,
  external,
  original,
  ...props
}) => {
  const internalProps = external ? getExternalLinkProps() : {}

  return (
    <Box height="100%" width="100%" {...props}>
      <Box as={external ? 'a' : NextLinkFromReactRouter} to={href} href={href} {...internalProps}>
        <Image src={src} height={height} width={width} alt={alt} original={original} blurLoader />
      </Box>
    </Box>
  )
}

export default CarouseLinkItem
