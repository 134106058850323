import Box from 'components/Box/Box'
import Carousel from 'components/Carousel'
import CarouseLinkItem from 'components/Carousel/CarouseLinkItem'
import React, { useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { EffectFade } from 'swiper'
import { BannerDetail } from 'config/types/home'
import { targetBanner } from 'config/constants/banner'
import 'swiper/css/effect-fade'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import Text from 'components/Text'
import { useRouter } from 'hooks/useRouter'
import { useResponsiveWithoutSidebar } from 'state/app/hooks'
import { detectResponsiveBreakpoint } from 'utils/styled'

const StyledWrapCarousel = styled(Box)`
  position: relative;
  height: 100%;
  max-width: 100vw;
  overflow: hidden;
`

const WrapListTitle = styled(Box)`
  z-index: 2;
  width: 240px;

  padding: 48px 0;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 60px;
  min-height: 320px;

  display: none;
  flex-direction: column;

  background: linear-gradient(
    180deg,
    rgba(22, 25, 31, 0) 6.66%,
    rgba(22, 25, 31, 0.6) 19.32%,
    rgba(22, 25, 31, 0.6) 62.72%,
    rgba(22, 25, 31, 0) 95.37%
  );

  ${({ theme }) => theme.mediaQueries.xl} {
    padding: 40px 0;

    display: flex;
    justify-content: center;
  }

  ${({ theme }) => theme.mediaQueries.xxl} {
    padding: 48px 0;
  }
`

const StyledItemTitleBanner = styled(Text).attrs({ color: 'textTertiary' })<{ $active: boolean }>`
  padding: 8px 12px;
  font-size: 12px;
  line-height: 18px;
  height: 50px;
  width: 100%;
  letter-spacing: -0.02em;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.textTertiary};

  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.xxxl} {
    font-size: 13px;
  }

  ${({ $active }) =>
    $active
      ? css`
          color: #fff;
          background: linear-gradient(90deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 98.54%);
        `
      : ''}
`

const getShadownSide = ({ side, theme }) => {
  switch (side) {
    case 'top':
      return css`
        top: 0;
        left: 0;
        transform: scaleY(-1);
        width: 100%;
        height: 45px;
        background: linear-gradient(180deg, rgba(22, 22, 26, 0), rgba(22, 22, 26, 0.8) 100%);

        ${theme.mediaQueries.xxl} {
          height: 90px;
        }
      `
    case 'left':
      return css`
        top: 0;
        left: 0;
        width: 60px;
        height: 100%;
        background-image: linear-gradient(270deg, rgba(22, 22, 26, 0), ${theme.colors.background});

        ${theme.mediaQueries.xxl} {
          width: 86px;
        }
      `
    case 'right':
      return css`
        top: 0;
        right: 0;
        width: 60px;
        height: 100%;
        background-image: linear-gradient(90deg, rgba(22, 22, 26, 0), ${theme.colors.background});

        ${theme.mediaQueries.xxl} {
          width: 86px;
        }
      `
    case 'bottom':
      return css`
        bottom: 0;
        width: 100%;
        height: 60px;
        background: linear-gradient(180deg, rgba(22, 22, 26, 0), ${theme.colors.background} 100%);

        ${theme.mediaQueries.xxl} {
          height: 86px;
        }
      `

    default:
      return css``
  }
}

export const BoxShadowSide = styled(Box)<{ side: 'top' | 'left' | 'right' | 'bottom' }>`
  position: absolute;
  pointer-events: none;
  z-index: 2;
  ${getShadownSide};

  display: none;

  ${({ theme }) => theme.mediaQueries.lg} {
    display: block;
  }
`

const HomeCarousel: React.FC<{ banners: BannerDetail[] }> = ({ banners }) => {
  const controlledRef = useRef(null)
  const router = useRouter()

  const [activeSlide, setActiveSlide] = useState(0)
  const { isXxl, isXxxl } = useMatchBreakpoints()

  const responsive = useResponsiveWithoutSidebar()

  const isRightMenuDisplay = useMemo(() => {
    const responsiveConfig = responsive([false, false, false, false, false, true])

    return detectResponsiveBreakpoint(responsiveConfig)
  }, [isXxl, isXxxl, responsive])

  const slidesBanner = useMemo(() => {
    return banners.map((banner, index) => {
      const responsiveConfig = responsive([
        [576, 240, banner.mobileImageSrc],
        [576, 240, banner.mobileImageSrc],
        [1920, 600, banner.imageSrc],
      ])

      const [width, height, src] = detectResponsiveBreakpoint(responsiveConfig)

      return {
        key: index,
        content: (
          <CarouseLinkItem
            alt={banner.url}
            external={banner.target === targetBanner[2]}
            src={src}
            href={banner.url}
            width={width}
            height={height}
            original
          />
        ),
      }
    })
  }, [banners, responsive])

  const pbBanner = ['57.97%', '', '31.25%']

  return (
    <StyledWrapCarousel>
      <BoxShadowSide side="top" />
      <BoxShadowSide side="left" />
      <BoxShadowSide side="right" />
      <BoxShadowSide side="bottom" />

      {isRightMenuDisplay ? (
        <>
          <Carousel
            pb={responsive(pbBanner)}
            effect="fade"
            loop
            autoplay={{
              delay: 5000,
            }}
            slides={slidesBanner}
            modules={[EffectFade]}
            onSwiper={(swiper) => {
              controlledRef.current = swiper
            }}
            onActiveSlideChange={(index) => setActiveSlide(index)}
          />
          <WrapListTitle>
            {banners.map((banner, index) => (
              <StyledItemTitleBanner
                onClick={() => {
                  if (banner.url) {
                    if (banner.target === targetBanner[2]) {
                      window.open(banner.url, '_blank')
                    } else {
                      router.push(banner.url)
                    }
                  }
                }}
                key={`banner-title--${index}`}
                $active={activeSlide === index}
                onMouseOver={() => {
                  controlledRef.current.slideToLoop(index)
                }}
              >
                {banner.title}
              </StyledItemTitleBanner>
            ))}
          </WrapListTitle>
        </>
      ) : (
        <Carousel
          pb={responsive(pbBanner)}
          slides={slidesBanner}
          loop
          autoplay={{
            delay: 5000,
          }}
          pagination
        />
      )}
    </StyledWrapCarousel>
  )
}
export default HomeCarousel
