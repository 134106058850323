import Image from 'components/Image'
import Link from 'components/Link'
import { targetBanner } from 'config/constants/banner'
import { BannerDetail } from 'config/types/home'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import React from 'react'
import theme from 'theme'

const SmallBanner: React.FC<{ smallBanner: BannerDetail }> = ({ smallBanner }) => {
  const { isMobile } = useMatchBreakpoints()

  return (
    smallBanner && (
      <Link
        href={smallBanner.url}
        external={smallBanner.target === targetBanner[2]}
        width="100%"
        height="fit-content"
        style={{
          overflow: 'hidden',
          borderRadius: theme.radii.tiny,
        }}
      >
        <Image
          style={{
            width: '100%',
          }}
          src={isMobile ? smallBanner.mobileImageSrc : smallBanner.imageSrc}
          key={'small-banner'}
          width={1792}
          height={135}
          alt="small-banner"
          blurLoader
        />
      </Link>
    )
  )
}

export default SmallBanner
