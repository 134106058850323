import { BannerTag } from 'config/constants/banner'
import { BannerDetail } from 'config/types/home'
import { useCallback } from 'react'
import { useAuth } from 'state/auth/hooks'
import { useUserWelcomePackage } from 'state/bonus/hooks'

const useFilterBannerCallback = () => {
  const userWelcomepack = useUserWelcomePackage()
  const { isSigned } = useAuth()
  const isWelcomePack = isSigned && userWelcomepack.expiredAt.getTime() > 0

  const filter = useCallback(
    (banners: BannerDetail[]) => {
      return banners.filter((banner) => {
        switch (banner.tag) {
          case BannerTag.WelcomePackage:
            return (isWelcomePack && !isSigned) || (isSigned && isWelcomePack) || (!isWelcomePack && !isSigned)
          case BannerTag.NonWelcomePackage:
            return !((isWelcomePack && !isSigned) || (isSigned && isWelcomePack) || (!isWelcomePack && !isSigned))
          default:
            return true
        }
      })
    },
    [isSigned, isWelcomePack],
  )

  return filter
}

export default useFilterBannerCallback
