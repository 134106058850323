import { BannerDetail } from 'config/types/home'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import Image from 'components/Image'
import Link from 'components/Link'
import Flex from 'components/Box/Flex'
import { FlexProps } from 'styled-system'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/autoplay'
import Carousel from 'components/Carousel'
import { targetBanner } from 'config/constants/banner'
import { useResponsiveWithoutSidebar } from 'state/app/hooks'
import { detectResponsiveBreakpoint } from 'utils/styled'

const SubBanner: React.FC<{ banners: BannerDetail[] } & FlexProps> = ({ banners = [], ...props }) => {
  const breakPoints = useMatchBreakpoints()
  const responsive = useResponsiveWithoutSidebar()
  const { isDesktop, isXl } = breakPoints

  const slidePerView = useMemo(() => {
    const responsiveConfig = responsive([2, 2, 2, 3, 3, 3, 4])
    return detectResponsiveBreakpoint(responsiveConfig)
  }, [breakPoints, responsive])

  const slides = useMemo(
    () =>
      banners.map((banner) => ({
        key: `sub-banner-${banner.id}`,
        content: (
          <StyledWrapLinkBanner
            external={banner.target === targetBanner[2]}
            aria-label={`${banner.id}`}
            href={banner.url}
          >
            <Image
              src={banner.imageSrc}
              style={{
                width: '100%',
              }}
              alt="banner"
              width={500}
              height={250}
              blurLoader
            />
          </StyledWrapLinkBanner>
        ),
      })),
    [banners],
  )

  return (
    <Wrapper {...props}>
      <Carousel
        pb={[`calc(50% / ${slidePerView})`]}
        loop={slidePerView < banners.length}
        autoplay={
          slidePerView < banners.length
            ? {
                delay: 8000,
              }
            : false
        }
        slides={slides}
        slidesPerView={slidePerView}
        spaceBetween={isDesktop && !isXl ? 20 : 12}
      />
    </Wrapper>
  )
}

const Wrapper = styled(Flex)`
  width: 100%;
  height: 100%;
`

const StyledWrapLinkBanner = styled(Link)`
  border-radius: 8px;
  overflow: hidden;

  cursor: pointer;
`

export default SubBanner
