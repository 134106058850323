import Flex from 'components/Box/Flex'
import Grid from 'components/Box/Grid'
import { ResponsiveGameCollection } from 'config/types/game'
import styled from 'styled-components'
import { HunnyTheme } from 'theme/types'
import { buildResponsiveStyle } from 'utils/styled'
import GameItem from 'views/Games/components/GameItem'
import ProviderBannerItem from './ProviderBannerItem'

export const buildResponsiveContainerGap = (props: ResponsiveGameCollection & { theme: HunnyTheme }) => {
  const { gap, theme } = props
  const parsedGap = Array.isArray(gap) ? gap.map((item) => `calc(100% + ${item * 2}px)`) : `calc(100% + ${gap * 2}px)`
  return buildResponsiveStyle(theme, 'width', parsedGap)
}

export const buildResponsiveContainerMarginLeft = (props: ResponsiveGameCollection & { theme: HunnyTheme }) => {
  const { gap, theme } = props
  const parsedGap = Array.isArray(gap) ? gap.map((item) => `-${item}px`) : `-${gap}px`
  return buildResponsiveStyle(theme, 'margin-left', parsedGap)
}

export const buildGameItemPadding = (props: ResponsiveGameCollection & { theme: HunnyTheme }) => {
  const { gap, theme } = props
  const parsedGap = Array.isArray(gap) ? gap.map((item) => `${item}px`) : `${gap}px`
  return buildResponsiveStyle(theme, 'padding', parsedGap)
}

export const buildGridTemplate = (props: ResponsiveGameCollection & { theme: HunnyTheme }) => {
  const { col, row, theme, itemCount, isFeature } = props
  const getGridTemplates = (col: number) => {
    const count = isFeature ? itemCount - 1 : itemCount

    return isFeature
      ? `${(2 * 100) / col}% repeat(${Math.round(count / row)}, calc(${100 / col}%))`
      : `repeat(${Math.round(count / row)}, calc(${100 / col}%))`
  }

  const parsedTemplate = Array.isArray(col) ? col.map((item) => getGridTemplates(item)) : getGridTemplates(col)

  return buildResponsiveStyle(theme, 'grid-template-columns', parsedTemplate)
}

export const StyledGridGame = styled(Grid)`
  scroll-snap-type: x mandatory;
  overflow: auto;
  width: 100%;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
`
export const StyledGameBannerItem = styled(GameItem)``

export const StyledGameProviderItem = styled(ProviderBannerItem)``

export const StyledContainerGameCollection = styled(Flex)<ResponsiveGameCollection>`
  ${buildResponsiveContainerGap}
  ${buildResponsiveContainerMarginLeft}

  ${StyledGridGame} {
    ${buildGridTemplate}
    ${StyledGameBannerItem} {
      ${buildGameItemPadding}
    }
    ${StyledGameProviderItem} {
      ${buildGameItemPadding}
    }
  }
`
