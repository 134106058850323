import Box from 'components/Box/Box'
import useComponentOnScreen from 'hooks/useComponentOnScreen'
import { useRequest } from 'hooks/useRequest'
import React, { useEffect, useState } from 'react'
import { SpaceProps } from 'styled-system'
import GameService from 'services/GameService'
import Text from 'components/Text'
import styled, { css } from 'styled-components'
import Flex from 'components/Box/Flex'
import Image from 'components/Image'
import { getFullDisplayBalance } from 'utils/formatBalance'
import BigNumber from 'bignumber.js'
import { TopWin } from 'services/types'
import { useTranslation } from 'react-i18next'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { useSidebarInfo, useMenuContainer } from 'state/app/hooks'
import { breakpointMap } from 'theme/base'
import { buildResponsiveStyledSystemWithExceptedComponent } from 'utils/styled'

const getRankDisplay = (rank: number) => {
  switch (rank) {
    case 1:
      return <StyledRankNumber color="#DC4F00">1</StyledRankNumber>
    case 2:
      return <StyledRankNumber color="#DC7600">2</StyledRankNumber>
    case 3:
      return <StyledRankNumber color="#F3C622">3</StyledRankNumber>
    default:
      return (
        <StyledRankNumber color="textSubtle" disabledNoText>
          {rank}
        </StyledRankNumber>
      )
  }
}
const TopWinList: React.FC<SpaceProps> = ({ ...props }) => {
  const { sidebarWidth } = useSidebarInfo()
  const { menuWidth } = useMenuContainer()

  const [topWins, setTopWins] = useState(null)
  const { t } = useTranslation()
  const { execute } = useRequest()
  const { isLoaded, ref } = useComponentOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  })

  useEffect(() => {
    const fetch = async () => {
      if (isLoaded) {
        const response = await execute(GameService.getTopWins())
        setTopWins(response.data.items.slice(0, 5))
      }
    }

    fetch()
  }, [isLoaded])

  const items: TopWin[] = topWins || Array(5)

  return (
    <Flex
      ref={ref}
      {...props}
      overflow="hidden"
      flexDirection="column"
      flex="1 1"
      mt={buildResponsiveStyledSystemWithExceptedComponent(
        ['2px', '4px', '4px', '6px', '6px', '10px'],
        sidebarWidth + menuWidth,
        breakpointMap.sm,
      )}
      mb={buildResponsiveStyledSystemWithExceptedComponent(
        ['2px', '4px', '4px', '6px', '6px', '10px'],
        sidebarWidth + menuWidth,
        breakpointMap.sm,
      )}
    >
      {items.map(
        (item, index) =>
          item && (
            <StyledTopWinItemContainer
              as={NextLinkFromReactRouter}
              key={item.gameName}
              borderRadius="8px"
              flex="1 1"
              alignItems="center"
              to={item.gameSlug}
              title="Play this game"
              py="4px"
              mb={
                index !== items.length - 1 &&
                buildResponsiveStyledSystemWithExceptedComponent(
                  ['8px', '18px', '18px', '18px', '6px', '8px', '6px', '18px'],
                  sidebarWidth + menuWidth,
                  breakpointMap.sm,
                )
              }
            >
              {getRankDisplay(index + 1)}

              <Box
                ml={buildResponsiveStyledSystemWithExceptedComponent(
                  ['12px', '12px', '24px', '12px'],
                  sidebarWidth + menuWidth,
                  breakpointMap.sm,
                )}
                style={{ aspectRatio: '1/1' }}
                height="100%"
                position="relative"
              >
                <StyledImage width={160} height={160} src={item.thumbnail} blurLoader />
              </Box>

              <Flex
                flexDirection="column"
                justifyContent="space-around"
                height="100%"
                padding={buildResponsiveStyledSystemWithExceptedComponent(
                  ['0px 0px 0px 12px', '', '', '0px', '', '', '4px 0px'],
                  sidebarWidth + menuWidth,
                  breakpointMap.sm,
                )}
                ml={buildResponsiveStyledSystemWithExceptedComponent(
                  ['0px', '0px', '0px', '12px'],
                  sidebarWidth + menuWidth,
                  breakpointMap.sm,
                )}
              >
                <StyledAmount color="primary">${getFullDisplayBalance(new BigNumber(item.amount))}</StyledAmount>
                <StyledGameName color="textTertiary" mt="4px" bold>
                  {item.gameName}
                </StyledGameName>
                <StyledProviderName color="textSubtle">{item.providerName || t('Unknow')}</StyledProviderName>
              </Flex>
            </StyledTopWinItemContainer>
          ),
      )}
    </Flex>
  )
}

const StyledRankNumber = styled(Text)<{ disabledNoText?: boolean }>`
  font-size: 32px;
  width: 32px;
  font-weight: 650;
  font-style: italic;
  line-height: 1em;
  position: relative;
  z-index: 1;
  text-align: center;

  ${({ disabledNoText }) =>
    !disabledNoText &&
    css`
      &::before {
        content: 'NO';
        font-family: 'roboto';
        font-size: 24px;
        font-weight: 900;
        font-style: italic;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        left: 0px;
        position: absolute;
        top: 0px;
        z-index: 0;
        letter-spacing: -0.02em;
      }
    `}

  ${({ theme }) => theme.mediaQueries.xs} {
    font-size: 32px;
    width: 32px;

    &::before {
      font-size: 24px;
    }
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 38px;
    width: 38px;

    &::before {
      font-size: 32px;
    }
  }

  ${({ theme }) => theme.mediaQueries.xxxl} {
    font-size: 44px;
    width: 52px;

    &::before {
      font-size: 40px;
    }
  }
`
const StyledProviderName = styled(Text)`
  display: flex;
  font-size: 12px;
  margin-top: 8px;

  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 14px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 10px;
    margin-top: 4px;
  }

  ${({ theme }) => theme.mediaQueries.xxl} {
    font-size: 12px;
  }
`

const StyledAmount = styled(Text)`
  font-size: 16px;

  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 24px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 16px;
  }

  ${({ theme }) => theme.mediaQueries.xxxl} {
    font-size: 24px;
  }
`

const StyledGameName = styled(Text)`
  margin-top: 8px;
  font-size: 12px;
  font-weight: 500;

  ${({ theme }) => theme.mediaQueries.xs} {
    font-size: 14px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 16px;
    margin-top: 4px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    margin-top: 2px;
    font-size: 12px;
  }

  ${({ theme }) => theme.mediaQueries.xxxl} {
    font-size: 14px;
    margin-top: 4px;
  }
`

const StyledImage = styled(Image)`
  position: absolute;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`

const StyledTopWinItemContainer = styled(Flex)`
  &:hover {
    background: ${({ theme }) => theme.colors.backgroundAlt5};
  }
`

export default TopWinList
