import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import { BoxProps } from 'components/Box/types'
import Image from 'components/Image'
import Link from 'components/Link'
import Text from 'components/Text'
import { HomeCategory } from 'config/types/home'
import { RowCenter } from 'layout/Components/Row'
import React from 'react'
import { Trans } from 'react-i18next'
import { useResponsiveWithoutSidebar, useToggleSidebar } from 'state/app/hooks'
import styled, { css } from 'styled-components'

const StyledTabContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  padding: 6px 10px;

  ${Text} {
    transition: ${({ theme }) => theme.transitions.fast};
    text-align: center;
    font-weight: 400;
  }

  &:hover,
  &:focus-visible {
    ${Text} {
      color: ${({ theme }) => theme.colors.text};
    }
  }
`

const StyledContainer = styled(Flex)<{ $length: number; $sidebarOpen: boolean }>`
  width: 100%;
  justify-content: center;

  a {
    flex: 1 1;
    max-width: ${({ $length }) => 100 / Math.round($length / 2)}%;
    min-width: ${({ $length }) => 100 / Math.round($length / 2)}%;
    width: ${({ $length }) => 100 / Math.round($length / 2)}%;

    ${({ $sidebarOpen }) =>
      $sidebarOpen
        ? css`
            ${({ theme }) => theme.mediaQueries.xl} {
              max-width: 10%;
              min-width: 10%;
              width: 10%;
            }
          `
        : css`
            ${({ theme }) => theme.mediaQueries.lg} {
              max-width: 10%;
              min-width: 10%;
              width: 10%;
            }
          `}
  }
`

const GameTags: React.FC<
  {
    categories: HomeCategory[]
  } & BoxProps
> = ({ categories, ...props }) => {
  const responsive = useResponsiveWithoutSidebar()
  const { isSidebarOpen } = useToggleSidebar()
  return (
    <Box {...props}>
      <RowCenter maxWidth="1456px" mx="auto">
        <StyledContainer flexWrap="wrap" $length={categories.length} $sidebarOpen={isSidebarOpen}>
          {categories.map((tag) => {
            return (
              <StyledTabContainer href={tag.url} key={tag.title}>
                <Box width={['24px', '32px']} height={['24px', '32px']}>
                  <Image src={tag.iconSrc} alt={tag.title} height={64} width={64} />
                </Box>
                <Text
                  bold
                  fontSize={responsive(['10px', '10px', '12px'])}
                  mt={['8px']}
                  color="text"
                  lineHeight="0.9rem"
                >
                  <Trans>{tag.title}</Trans>
                </Text>
              </StyledTabContainer>
            )
          })}
        </StyledContainer>
      </RowCenter>
    </Box>
  )
}

export default GameTags
